import { useContext, useEffect, useState } from "react";
import { AccessibilityProps } from "@/models";
import { Context } from "@/context";

export interface Image360Item {
  id?: string;
  index?: number;
  src: string;
  accessibility?: AccessibilityProps;
}

function preloadImage(src: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      reject(src);
    };
    img.src = src;
  });
}

const imagesResult: HTMLImageElement[] = [];

export const useImagePreloader = (imageList: Image360Item[]) => {
  const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false);

  const { dispatch } = useContext(Context);

  useEffect(() => {
    if (imagesPreloaded) {
      return;
    }

    let isCancelled = false;

    async function effect() {
      if (isCancelled) {
        return false;
      }

      const imagesPromiseList: Promise<any>[] = [];
      for (const i of imageList) {
        const p = preloadImage(i.src);
        imagesPromiseList.push(p);
        const images: unknown = await preloadImage(i.src);
        imagesResult.push(<HTMLImageElement>images);
      }

      await Promise.all(imagesPromiseList);

      if (isCancelled) {
        return;
      }

      dispatch({
        type: "SET_RESERVE_CTA_IMAGES",
        payload: {
          reserveCtaImages: imagesResult,
        },
      });

      dispatch({
        type: "IS_IMAGES_PRELOADED",
        payload: {
          imagesPreloaded: true,
        },
      });

      setImagesPreloaded(true);
    }

    effect();

    return () => {
      isCancelled = true;
    };
  }, [imageList]);

  return { imagesPreloaded, imagesResult };
};
