import {MenuLabels, NavRefs} from "@/models";
import {layoutInitialState} from "@/reducers/layoutReducer";
import {MutableRefObject} from "react";

export const setCurrentlyVisibleNav = (view: MenuLabels, suffix = '', queryStringParam?: URLSearchParams | null): MenuLabels['id'] => {
    const href = suffix ? `${view.slug}/${suffix}` : view.slug;

    if (!layoutInitialState.stopHistoryChange) {
        try {
            const queryString = !!layoutInitialState.queryString
                ? `?${queryStringParam}`
                : '';
            window.history.replaceState(
                {section: view},
                '',
                `${
                    process.env.BASE_PREFIX ?? '/'
                }${href}${queryStringParam ? `?${queryStringParam}` : ''}`.replace('//', '/'),
            );
        } catch (_e) {
            console.error(_e);
        }
    }
    if(view.prefixRelation) {
        return view.prefixRelation.id
    }
    return view.id;
}

export const setSlugSuffixes = (reference: MenuLabels, func: (slugSuffix: string) => void): any => {
    const test = {
        ...reference,
        [reference.slug]: func,
    }
    return {
        ...reference,
        [reference.slug]: func
    }
}

export const setNavRefs = (slug: MenuLabels['slug'], ref: MutableRefObject<unknown>): NavRefs => {
    return {
        [slug]: ref
    }
}
