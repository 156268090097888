import { FC } from 'react';

const ConnectMeSvg: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="300.454" height="48.662" viewBox="0 0 300.454 48.662">
      <g id="Group_3837" data-name="Group 3837" transform="translate(-671 -850)">
        <g id="logo-connect.55991cad" transform="translate(236.004 -577.5)">
          <path id="Path_2196" data-name="Path 2196" d="M1157.239,1436.539H1147.6v5.214h9.105v6.87H1147.6V1454h9.643v6.87h-17.755V1429.66h17.755Z" transform="translate(-421.789 -1.301)" fill="#fff" />
          <g id="Group_3835" data-name="Group 3835" transform="translate(636.434 1428.419)">
            <path id="Path_2197" data-name="Path 2197" d="M941.447,1460.947h-4.47l10.015-31.156h4.47Z" transform="translate(-936.977 -1429.79)" fill="#fff" />
            <path id="Path_2198" data-name="Path 2198" d="M965.056,1460.947h-4.47L970.6,1429.79h4.47Z" transform="translate(-951.112 -1429.79)" fill="#fff" />
            <path id="Path_2199" data-name="Path 2199" d="M988.664,1460.947h-4.47l10.015-31.156h4.47Z" transform="translate(-965.246 -1429.79)" fill="#fff" />
            <path id="Path_2200" data-name="Path 2200" d="M1011.939,1460.947h-4.47l10.015-31.156h4.47Z" transform="translate(-979.182 -1429.79)" fill="#fff" />
          </g>
          <path id="Path_2201" data-name="Path 2201" d="M458.882,1438.8a8.657,8.657,0,0,0-6.954-3.435,8.506,8.506,0,0,0-3.373.667,8.083,8.083,0,0,0-4.428,4.507,8.884,8.884,0,0,0-.641,3.393,8.992,8.992,0,0,0,.641,3.435,8.309,8.309,0,0,0,7.762,5.219,9.39,9.39,0,0,0,6.994-3.31v9.6l-.835.29a23.923,23.923,0,0,1-3.477.973,16.848,16.848,0,0,1-3.186.311,16.285,16.285,0,0,1-11.4-4.657,17.059,17.059,0,0,1-3.621-5.256,16.859,16.859,0,0,1-.022-13.243,16.481,16.481,0,0,1,15.086-9.788,17.118,17.118,0,0,1,3.664.394,22.224,22.224,0,0,1,3.786,1.22Z" transform="translate(0 0.002)" fill="#fff" />
          <path id="Path_2202" data-name="Path 2202" d="M502.348,1443.972a16.315,16.315,0,0,1,1.282-6.478,16.013,16.013,0,0,1,9-8.731,19.837,19.837,0,0,1,13.927,0,16.458,16.458,0,0,1,5.483,3.505,16.016,16.016,0,0,1,3.58,5.234,17,17,0,0,1,0,12.954,16.006,16.006,0,0,1-3.581,5.236,16.423,16.423,0,0,1-5.483,3.5,19.828,19.828,0,0,1-13.928,0,16.137,16.137,0,0,1-10.285-15.209Zm8.484,0a8.307,8.307,0,0,0,.7,3.435,8.592,8.592,0,0,0,4.7,4.532,9.178,9.178,0,0,0,6.747,0,8.847,8.847,0,0,0,2.814-1.8,8.537,8.537,0,0,0,1.925-2.731,8.737,8.737,0,0,0,0-6.87,8.539,8.539,0,0,0-1.925-2.731,8.857,8.857,0,0,0-2.814-1.8,9.2,9.2,0,0,0-6.747,0,8.6,8.6,0,0,0-4.7,4.534,8.307,8.307,0,0,0-.7,3.432Z" transform="translate(-40.321)" fill="#fff" />
          <path id="Path_2203" data-name="Path 2203" d="M686.678,1460.874v-31.213h8.112l14.981,19.08v-19.08h8.07v31.213h-8.07L694.79,1441.8v19.079Z" transform="translate(-150.685 -1.3)" fill="#fff" />
          <path id="Path_2204" data-name="Path 2204" d="M598.416,1460.874v-31.213h8.112l14.981,19.08v-19.08h8.07v31.213h-8.07L606.528,1441.8v19.079Z" transform="translate(-97.838 -1.3)" fill="#fff" />
          <path id="Path_2205" data-name="Path 2205" d="M792.821,1436.539h-9.643v5.214h9.105v6.87h-9.105V1454h9.643v6.87H775.059V1429.66h17.762Z" transform="translate(-203.599 -1.301)" fill="#fff" />
          <path id="Path_2206" data-name="Path 2206" d="M851.645,1438.8a8.658,8.658,0,0,0-6.954-3.435,8.51,8.51,0,0,0-3.373.667,8.083,8.083,0,0,0-4.427,4.507,8.874,8.874,0,0,0-.643,3.394,8.979,8.979,0,0,0,.643,3.435,8.285,8.285,0,0,0,7.76,5.215,9.393,9.393,0,0,0,6.994-3.31v9.6l-.835.29a23.97,23.97,0,0,1-3.475.974,16.877,16.877,0,0,1-3.188.31,16.286,16.286,0,0,1-11.4-4.657,17.073,17.073,0,0,1-3.62-5.256,16.859,16.859,0,0,1-.022-13.243,16.481,16.481,0,0,1,15.086-9.788,17.132,17.132,0,0,1,3.664.394,22.2,22.2,0,0,1,3.787,1.22Z" transform="translate(-235.156 0)" fill="#fff" />
          <path id="Path_2207" data-name="Path 2207" d="M906.934,1436.535v24.335h-8.112v-24.335h-6.663v-6.87h21.435v6.87Z" transform="translate(-273.71 -1.298)" fill="#fff" />
          <path id="Path_2208" data-name="Path 2208" d="M1031.826,1460.875l9.927-31.213h8.029l3.782,16.638,8.68-16.638h8.029l.133,31.213h-7.692l.129-17.962-10.03,17.962h-3.228l-4.331-17.962-5.355,17.962Z" transform="translate(-357.332 -1.299)" fill="#fff" />
          <g id="Group_3836" data-name="Group 3836" transform="translate(674.277 1463.845)">
            <rect id="Rectangle_2244" data-name="Rectangle 2244" width="1.809" height="12.12" transform="translate(4.25 0.007)" fill="#fff" />
            <path id="Path_2209" data-name="Path 2209" d="M1031.286,1530.216h1.809v-5.487h1.324v-1.4H1033.1v-3.839h1.7v-1.4h-3.505Z" transform="translate(-1031.286 -1518.09)" fill="#fff" />
            <path id="Path_2210" data-name="Path 2210" d="M1061.231,1518.093v1.4h1.294v10.723h1.788V1519.49h1.309v-1.4Z" transform="translate(-1049.215 -1518.086)" fill="#fff" />
            <path id="Path_2211" data-name="Path 2211" d="M1050.431,1523.329v-3.854h.883a10.447,10.447,0,0,1,.668,2.39c.14.794.214,1.464.214,1.464Zm1.986-5.236h-3.61v12.12h1.648v-5.48h1.816c.167,1,.177,5.156.177,5.48h1.669c.05-3.081.021-8.81-1.7-12.12" transform="translate(-1041.776 -1518.086)" fill="#fff" />
          </g>
          <path id="Path_2212" data-name="Path 2212" d="M1083.337,1518.439v1.452a2.5,2.5,0,0,0-1.04-.3,1.7,1.7,0,0,0-.987.282,2.06,2.06,0,0,0-.679.873,5.539,5.539,0,0,0-.4,1.5,15.462,15.462,0,0,0-.127,2.138q0,4.44,2.273,4.44a2.426,2.426,0,0,0,.955-.249v1.482a2.62,2.62,0,0,1-1.3.333q-3.64,0-3.64-5.945a10,10,0,0,1,.888-4.782,2.924,2.924,0,0,1,2.721-1.567A2.783,2.783,0,0,1,1083.337,1518.439Z" transform="translate(-385.216 -54.228)" fill="#fff" />
          <path id="Path_2213" data-name="Path 2213" d="M1098.976,1524.55a10.374,10.374,0,0,1-.706,4.506,2.466,2.466,0,0,1-2.367,1.336,2.524,2.524,0,0,1-2.46-1.435,12.252,12.252,0,0,1-.706-4.991,10.5,10.5,0,0,1,.7-4.525,2.485,2.485,0,0,1,2.39-1.335,2.552,2.552,0,0,1,2.235,1.033,4.89,4.89,0,0,1,.7,1.963A20.374,20.374,0,0,1,1098.976,1524.55Zm-1.693.167a19.165,19.165,0,0,0-.279-4.129q-.28-1.024-1.14-1.025a1.143,1.143,0,0,0-1.148.94,16.342,16.342,0,0,0-.279,3.8,15.091,15.091,0,0,0,.3,3.647,1.158,1.158,0,0,0,1.133.963,1.131,1.131,0,0,0,1.117-.9,11.889,11.889,0,0,0,.3-3.3Z" transform="translate(-393.805 -54.249)" fill="#fff" />
          <path id="Path_2214" data-name="Path 2214" d="M1111.2,1518.489h1.5l1.608,4.782a43.542,43.542,0,0,1,1.234,4.325q-.194-1.39-.287-2.515t-.094-2.118v-4.471h1.623v11.985h-1.514l-1.808-5.395q-.247-.753-.469-1.544t-.409-1.669a2.3,2.3,0,0,0-.052-.264c-.025-.1-.05-.222-.082-.357l.04.346c.01.1.017.179.021.245l.087,1.218.085,1.482c0,.083.008.183.012.3s0,.254,0,.4l.085,5.224h-1.591Z" transform="translate(-404.855 -54.478)" fill="#fff" />
          <path id="Path_2215" data-name="Path 2215" d="M1127.892,1518.489h1.5l1.607,4.782a43.4,43.4,0,0,1,1.233,4.325q-.193-1.391-.287-2.516t-.094-2.118v-4.471h1.623v11.985h-1.514l-1.809-5.395q-.247-.753-.469-1.544t-.407-1.669a2.682,2.682,0,0,0-.05-.264c-.023-.1-.05-.222-.082-.357l.038.346q.015.145.023.245l.087,1.218.085,1.482a2.969,2.969,0,0,0,.012.3c.011.12,0,.254,0,.4l.085,5.224h-1.591Z" transform="translate(-414.85 -54.478)" fill="#fff" />
          <path id="Path_2216" data-name="Path 2216" d="M1144.584,1518.489h4.291v1.367h-2.671v3.8h2.375v1.365h-2.375v4.091h2.671v1.365h-4.291Z" transform="translate(-424.843 -54.478)" fill="#fff" />
          <path id="Path_2217" data-name="Path 2217" d="M1162.26,1518.439v1.452a2.5,2.5,0,0,0-1.04-.3,1.7,1.7,0,0,0-.985.282,2.06,2.06,0,0,0-.679.873,5.468,5.468,0,0,0-.4,1.5,15.455,15.455,0,0,0-.129,2.138q0,4.44,2.274,4.44a2.444,2.444,0,0,0,.955-.249v1.482a2.615,2.615,0,0,1-1.3.334q-3.641,0-3.641-5.946a9.994,9.994,0,0,1,.888-4.782,2.923,2.923,0,0,1,2.721-1.567A2.785,2.785,0,0,1,1162.26,1518.439Z" transform="translate(-432.469 -54.228)" fill="#fff" />
          <path id="Path_2218" data-name="Path 2218" d="M1170.637,1518.489h5.254v1.412h-1.809v10.572H1172.5V1519.9h-1.863Z" transform="translate(-440.441 -54.478)" fill="#fff" />
          <path id="Path_2219" data-name="Path 2219" d="M996.8,1523h1.848q2.178,0,2.178,2.7a3.215,3.215,0,0,1-.284,1.534,1.769,1.769,0,0,1-1,.756,2.18,2.18,0,0,1,1.127.835,2.989,2.989,0,0,1,.311,1.527q0,2.8-2.4,2.8h-1.791Zm1.29,4.475a1.389,1.389,0,0,0,1.107-.369,2.253,2.253,0,0,0,.309-1.364q0-1.644-1.183-1.644h-.167a.172.172,0,0,0-.065,0Zm0,4.506a1.514,1.514,0,0,0,1.169-.362,2,2,0,0,0,.324-1.31,2.115,2.115,0,0,0-.334-1.362,1.434,1.434,0,0,0-1.152-.394Z" transform="translate(-336.36 -57.175)" fill="#fff" />
          <path id="Path_2220" data-name="Path 2220" d="M1007.645,1523h1.447l.668,2.212c.022.065.043.138.065.217s.048.167.078.257a15.8,15.8,0,0,1,.512,2.013c.08-.544.145-.925.2-1.139l.21-.91.078-.354.566-2.3h1.429l-1.769,5.792v4.37h-1.369v-4.37Z" transform="translate(-342.854 -57.175)" fill="#fff" />
        </g>
      </g>
    </svg>
  )
}

export default ConnectMeSvg;
