import { FC } from 'react';

const Arrow: FC<{ color?: string; title?: string }> = ({
  color = "#FFFFFF",
  title = "Próximo",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.12"
      height="16"
      viewBox="0 0 9.12 16"
    >
      <title id="arrowTitle">{title}</title>
      <path
        id="arrow_forward_ios_FILL0_wght400_GRAD0_opsz48"
        d="M249.12,190,248,188.86l6.86-6.86L248,175.14l1.12-1.14,8,8Z"
        transform="translate(-248 -174)"
        fill={color}
      />
    </svg>
  );
};

export default Arrow;

